const actions = {
    GET_PROVINCE_BEGIN: "GET_PROVINCE_BEGIN",
    GET_PROVINCE_SUCCESS: "GET_PROVINCE_SUCCESS",
    GET_PROVINCE_FAILED: "GET_PROVINCE_FAILED",

    GET_DISTRICT_BEGIN: "GET_DISTRICT_BEGIN",
    GET_DISTRICT_SUCCESS: "GET_DISTRICT_SUCCESS",
    GET_DISTRICT_FAILED: "GET_DISTRICT_FAILED",

    GET_LOCAL_BEGIN: "GET_LOCAL_BEGIN",
    GET_LOCAL_SUCCESS: "GET_LOCAL_SUCCESS",
    GET_LOCAL_FAILED: "GET_LOCAL_FAILED",

    getProvinceBegin: () => {
        return {
            type: actions.GET_PROVINCE_BEGIN,
        };
    },

    getProvinceSuccess: data => {
        return {
            type: actions.GET_PROVINCE_SUCCESS,
            data,
        };
    },

    getProvinceFailed: err => {
        return {
            type: actions.GET_PROVINCE_FAILED,
            err,
        };
    },

    getDistrictBegin: () => {
        return {
            type: actions.GET_DISTRICT_BEGIN,
        };
    },

    getDistrictSuccess: data => {
        return {
            type: actions.GET_DISTRICT_SUCCESS,
            data,
        };
    },

    getDistrictFailed: err => {
        return {
            type: actions.GET_DISTRICT_FAILED,
            err,
        };
    },

    getLocalBegin: () => {
        return {
            type: actions.GET_LOCAL_BEGIN,
        };
    },

    getLocalSuccess: data => {
        return {
            type: actions.GET_LOCAL_SUCCESS,
            data,
        };
    },

    getLocalFailed: err => {
        return {
            type: actions.GET_LOCAL_FAILED,
            err,
        };
    },

}

export default actions;