import actions from './actions';

const {
  GET_COUPON_BEGIN, GET_COUPON_SUCCESS, GET_COUPON_FAILED,
  ADD_COUPON_BEGIN, ADD_COUPON_SUCCESS, ADD_COUPON_FAILED,
  UPDATE_COUPON_BEGIN, UPDATE_COUPON_SUCCESS, UPDATE_COUPON_FAILED,
  DELETE_COUPON_BEGIN, DELETE_COUPON_SUCCESS, DELETE_COUPON_FAILED
} = actions;

const initState = {
  coupons: [],
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  loading: false,
  error: null,
};

const CouponReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_COUPON_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_COUPON_SUCCESS:
      return {
        ...state,
        coupons: data.coupons,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: false,
      };
    case GET_COUPON_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_COUPON_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_COUPON_SUCCESS:
      return {
        ...state,
        coupons: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_COUPON_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_COUPON_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        coupons: data,
        loading: false,
      };
    case UPDATE_COUPON_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_COUPON_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        coupons: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_COUPON_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default CouponReducer;