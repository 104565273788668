const actions = {
    GET_USER_REFERRAL_BEGIN: 'GET_USER_REFERRAL_BEGIN',
    GET_USER_REFERRAL_SUCCESS: 'GET_USER_REFERRAL_SUCCESS',
    GET_USER_REFERRAL_FAILED: 'GET_USER_REFERRAL_FAILED',

    CHANGE_USER_REFERRAL_BEGIN: 'CHANGE_USER_REFERRAL_BEGIN',
    CHANGE_USER_REFERRAL_SUCCESS: 'CHANGE_USER_REFERRAL_SUCCESS',
    CHANGE_USER_REFERRAL_FAILED: 'CHANGE_USER_REFERRAL_FAILED',


    getUserReferralBegin: () => {
        return {
            type: actions.GET_USER_REFERRAL_BEGIN,
        };
    },

    getUserReferralSuccess: data => {
        return {
            type: actions.GET_USER_REFERRAL_SUCCESS,
            data,
        };
    },

    getUserReferralFailed: err => {
        return {
            type: actions.GET_USER_REFERRAL_FAILED,
            err,
        };
    },

    changeUserReferralBegin: () => {
        return {
            type: actions.CHANGE_USER_REFERRAL_BEGIN,
        };
    },

    changeUserReferralSuccess: () => {
        return {
            type: actions.CHANGE_USER_REFERRAL_SUCCESS,

        };
    },

    changeUserReferralFailed: err => {
        return {
            type: actions.CHANGE_USER_REFERRAL_FAILED,
            err,
        };
    },
};

export default actions;