import actions from './actions';

const {
    GET_STORE_BEGIN, GET_STORE_SUCCESS, GET_STORE_FAILED,
} = actions;

const initState = {
    store: [],
    total: 0,
    currentPage: 1,
    pageLimit: 50000,
    loading: false,
    error: null,
};

const StoreReducer = (state = initState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_STORE_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_STORE_SUCCESS:
            return {
                ...state,
                store: data.store,
                total: data.total || 0,
                currentPage: data.currentPage || 1,
                pageLimit: data.pageLimit || state.pageLimit,
                loading: false,
            };
        case GET_STORE_FAILED:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};
export default StoreReducer;