import actions from './actions';

const {
    GET_SINGLE_ORDER_BEGIN, GET_SINGLE_ORDER_SUCCESS, GET_SINGLE_ORDER_FAILED,
    GET_ORDER_BEGIN, GET_ORDER_SUCCESS, GET_ORDER_FAILED,
    UPDATE_ORDER_BEGIN, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILED,
} = actions;

const initState = {
    orders: [],
    order: {},
    total: 0,
    currentPage: 1,
    pageLimit: 50,
    loading: false,
    error: null,
};

const OrderReducer = (state = initState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_ORDER_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                orders: data.orders,
                total: data.total || 0,
                currentPage: data.currentPage || 1,
                pageLimit: data.pageLimit || state.pageLimit,
                loading: false,
            };
        case GET_ORDER_FAILED:
            return {
                ...state,
                error: err,
                loading: false,
            };
        case GET_SINGLE_ORDER_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_SINGLE_ORDER_SUCCESS:
            return {
                ...state,
                order: data,
                loading: false,
            };
        case GET_SINGLE_ORDER_FAILED:
            return {
                ...state,
                error: err,
                loading: false,
            };
        case UPDATE_ORDER_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                orders: data,
                loading: false,
            };
        case UPDATE_ORDER_FAILED:
            return {
                ...state,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};
export default OrderReducer;