const actions = {
    GET_ORDER_BEGIN: 'GET_ORDER_BEGIN',
    GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
    GET_ORDER_FAILED: 'GET_ORDER_FAILED',

    GET_SINGLE_ORDER_BEGIN: 'GET_SINGLE_ORDER_BEGIN',
    GET_SINGLE_ORDER_SUCCESS: 'GET_SINGLE_ORDER_SUCCESS',
    GET_SINGLE_ORDER_FAILED: 'GET_SINGLE_ORDER_FAILED',

    UPDATE_ORDER_BEGIN: 'UPDATE_ORDER_BEGIN',
    UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
    UPDATE_ORDER_FAILED: 'UPDATE_ORDER_FAILED',

    getOrderBegin: () => {
        return {
            type: actions.GET_ORDER_BEGIN,
        };
    },

    getOrderSuccess: data => {
        return {
            type: actions.GET_ORDER_SUCCESS,
            data,
        };
    },

    getOrderFailed: err => {
        return {
            type: actions.GET_ORDER_FAILED,
            err,
        };
    },

    getSingleOrderBegin: () => {
        return {
            type: actions.GET_SINGLE_ORDER_BEGIN,
        };
    },

    getSingleOrderSuccess: data => {
        return {
            type: actions.GET_SINGLE_ORDER_SUCCESS,
            data,
        };
    },

    getSingleOrderFailed: err => {
        return {
            type: actions.GET_SINGLE_ORDER_FAILED,
            err,
        };
    },

    updateOrderBegin: () => {
        return {
            type: actions.UPDATE_ORDER_BEGIN,
        };
    },

    updateOrderSuccess: data => {
        return {
            type: actions.UPDATE_ORDER_SUCCESS,
            data,
        };
    },

    updateOrderFailed: err => {
        return {
            type: actions.UPDATE_ORDER_FAILED,
            err,
        };
    },
};

export default actions;