import actions from './actions';

const {
  GET_BANNER_BEGIN, GET_BANNER_SUCCESS, GET_BANNER_FAILED,
  ADD_BANNER_BEGIN, ADD_BANNER_SUCCESS, ADD_BANNER_FAILED,
  UPDATE_BANNER_BEGIN, UPDATE_BANNER_SUCCESS, UPDATE_BANNER_FAILED,
  DELETE_BANNER_BEGIN, DELETE_BANNER_SUCCESS, DELETE_BANNER_FAILED
} = actions;

const initState = {
  banners: [],
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  loading: false,
  error: null,
  banner:{}
};

const BannerReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_BANNER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_BANNER_SUCCESS:
      return {
        ...state,
        banners: data.banners,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: false
      };
    case GET_BANNER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_BANNER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_BANNER_SUCCESS:
      return {
        ...state,
        banners: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_BANNER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_BANNER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        banners: data,
        loading: false,
      };
    case UPDATE_BANNER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_BANNER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        banners: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_BANNER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default BannerReducer;