import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
// import Notification from './notification';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { checkUserAuthenticated, logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import Cookies from 'js-cookie';
import { IMAGE_URL } from '../../../utils/constants';
import { getImageUrl } from '../../../utility/utility';

const AuthInfo = () => {
  const [state, setState] = useState({ flag: Cookies.get('lang') ?? "en" });
  const path = window.location.pathname;
  const splitPath = path.split('/');
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(state => {
    return {
      user: state.auth.user,
    };
  });
  const user_role = Cookies.get("user_role");
  const info = JSON.parse(localStorage.getItem("user"));
  if (window.location.pathname !== '/admin')
    Cookies.set('currentPath', window.location.pathname);

  let profilePath = `${user_role}/profile`;
  if (splitPath.length === 3)
    profilePath = `../${user_role}/profile`;
  else if (splitPath.length === 4)
    profilePath = `../../${user_role}/profile`;
  else if (splitPath.length === 5)
    profilePath = `../../../${user_role}/profile`;

  // useEffect(() => {
  //   if (!localStorage.getItem("user"))
  //     // dispatch(checkUserAuthenticated(history));
  //     return <Redirect push  to="/login" />;
  // }, []);


  const SignOut = e => {
    dispatch(logOut());
    return <Redirect push to={"/superuser"} />
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={info?.image ? getImageUrl(info?.image) : require('../../../static/img/avatar/chat-auth.png')} alt="profile" style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: "contain" }} />
          <figcaption>
            <Heading as="h5">{info?.name}</Heading>
            <p>{info?.role}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={"/superuser/profile"}>
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
        </ul>
        <a className="user-dropdwon__bottomAction" onClick={SignOut}>
          <FeatherIcon icon="log-out" /> Sign Out
        </a>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    setState({ ...state, flag: value, });
    Cookies.set('lang', value);
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('en')} to="#">
        <img src={require('../../../static/img/flag/en.png')} alt="" width={10} height={10} />
        <span>English</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('nep')} to="#">
        <img src={require('../../../static/img/flag/nep.png')} alt="" width={10} height={10} />
        <span>Nepali</span>
      </Link>
    </NavAuth>
  );

  // if (!localStorage.getItem("user")) {
  //   return  <Redirect to={`/login`} />
  // }

  return (
    <InfoWraper>
      {/* <Message /> */}
      {/* <Notification /> */}

      {/* <Settings /> */}
      {/* <Support /> */}
      <div className="nav-author">
        {/* <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${state.flag}.png`)} alt="" />
          </Link>
        </Dropdown> */}
      </div>

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <img style={{ objectFit: "contain", height: "32px", width: "50px", borderRadius:"50%" }} src={info?.image ? getImageUrl(info?.image) : require('../../../static/img/avatar/chat-auth.png')} />
            {/* <Avatar  /> */}
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
