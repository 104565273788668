import actions from "./actions";

const initialState = {
    province: [],
    district: [],
    local: [],
    provinceLoading: false,
    provinceErr: null,
    districtLoading: false,
    districtErr: null,
    localLoading: false,
    localErr: null,
}

const PlaceReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case actions.GET_PROVINCE_BEGIN: return {
            ...state,
            provinceLoading: true,
        }
        case actions.GET_DISTRICT_BEGIN: return {
            ...state,
            districtLoading: true,
        }
        case actions.GET_LOCAL_BEGIN: return {
            ...state,
            localLoading: true,
        }
        case actions.GET_PROVINCE_FAILED: return {
            ...state,
            provinceLoading: false,
            provinceErr: err
        }
        case actions.GET_DISTRICT_FAILED: return {
            ...state,
            districtLoading: false,
            districtErr: err
        }
        case actions.GET_LOCAL_FAILED: return {
            ...state,
            localLoading: false,
            districtErr: err
        }
        case actions.GET_PROVINCE_SUCCESS: return {
            ...state,
            provinceLoading: false,
            province: data.province
        }
        case actions.GET_DISTRICT_SUCCESS: return {
            ...state,
            districtLoading: false,
            district: data.district
        }
        case actions.GET_LOCAL_SUCCESS: return {
            ...state,
            localLoading: false,
            local: data.local
        }
        default: return state;
    }
}

export default PlaceReducer;