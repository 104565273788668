import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import Cookies from 'js-cookie';
import admin from './routes/admin';
import Dashboard from './container/dashboard';

const { theme } = config;

const ProviderConfig = () => {
  // const user_role = Cookies.get("user_role");
  const isLoggedIn = localStorage.getItem("user")
  const { rtl, user, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      user: state.auth.user
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);
  
  const DashboardRoute = () => {
    return <Redirect to="/superuser" />;
  };



  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          <Route path="/login" component={Auth} /> <Route path={"/superuser"} component={admin} />
          <Route exact path="/" component={DashboardRoute} /> 
          {/* {user_role && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
            <>
              {
                user_role === 'superuser' ? <Redirect to="/superuser" /> : <Redirect to="/" />
              }
            </>
          )} */}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <ProviderConfig />
  );
}

export default hot(App);
