import actions from './actions';

const {
  GET_CONTACT_BEGIN, GET_CONTACT_SUCCESS, GET_CONTACT_FAILED,
  ADD_CONTACT_BEGIN, ADD_CONTACT_SUCCESS, ADD_CONTACT_FAILED,
  UPDATE_CONTACT_BEGIN, UPDATE_CONTACT_SUCCESS, UPDATE_CONTACT_FAILED,
  DELETE_CONTACT_BEGIN, DELETE_CONTACT_SUCCESS, DELETE_CONTACT_FAILED
} = actions;

const initState = {
  contacts: [],
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  loading: false,
  error: null,
  contact:{}
};

const ContactReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CONTACT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: data.contacts,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: false,
      };
    case GET_CONTACT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_CONTACT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_CONTACT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_CONTACT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: data,
        loading: false,
      };
    case UPDATE_CONTACT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_CONTACT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_CONTACT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
      case GET_CONTACT_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case GET_CONTACT_SUCCESS:
        return {
          ...state,
          contact: data,
          loading: false,
        };
      case GET_CONTACT_FAILED:
        return {
          ...state,
          error: err,
          loading: false,
        };
    default:
      return state;
  }
};
export default ContactReducer;