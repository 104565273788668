const actions = {
    GET_PAYABLE_STATEMENT_BEGIN: 'GET_PAYABLE_STATEMENT_BEGIN',
    GET_PAYABLE_STATEMENT_SUCCESS: 'GET_PAYABLE_STATEMENT_SUCCESS',
    GET_PAYABLE_STATEMENT_FAILED: 'GET_PAYABLE_STATEMENT_FAILED',

    GET_SINGLE_PAYABLE_STATEMENT_BEGIN: 'GET_SINGLE_PAYABLE_STATEMENT_BEGIN',
    GET_SINGLE_PAYABLE_STATEMENT_SUCCESS: 'GET_SINGLE_PAYABLE_STATEMENT_SUCCESS',
    GET_SINGLE_PAYABLE_STATEMENT_FAILED: 'GET_SINGLE_PAYABLE_STATEMENT_FAILED',

    UPDATE_PAYABLE_STATEMENT_BEGIN: 'UPDATE_PAYABLE_STATEMENT_BEGIN',
    UPDATE_PAYABLE_STATEMENT_SUCCESS: 'UPDATE_PAYABLE_STATEMENT_SUCCESS',
    UPDATE_PAYABLE_STATEMENT_FAILED: 'UPDATE_PAYABLE_STATEMENT_FAILED',

    getPayableStatementBegin: () => {
        return {
            type: actions.GET_PAYABLE_STATEMENT_BEGIN,
        };
    },

    getPayableStatementSuccess: data => {
        return {
            type: actions.GET_PAYABLE_STATEMENT_SUCCESS,
            data,
        };
    },

    getPayableStatementFailed: err => {
        return {
            type: actions.GET_PAYABLE_STATEMENT_FAILED,
            err,
        };
    },

    getSinglePayableStatementBegin: () => {
        return {
            type: actions.GET_SINGLE_PAYABLE_STATEMENT_BEGIN,
        };
    },

    getSinglePayableStatementSuccess: data => {
        return {
            type: actions.GET_SINGLE_PAYABLE_STATEMENT_SUCCESS,
            data,
        };
    },

    getSinglePayableStatementFailed: err => {
        return {
            type: actions.GET_SINGLE_PAYABLE_STATEMENT_FAILED,
            err,
        };
    },
    updatePayableStatementBegin: () => {
        return {
            type: actions.UPDATE_PAYABLE_STATEMENT_BEGIN,
        };
    },

    updatePayableStatementSuccess: data => {
        return {
            type: actions.UPDATE_PAYABLE_STATEMENT_SUCCESS,
            data,
        };
    },

    updatePayableStatementFailed: err => {
        return {
            type: actions.UPDATE_PAYABLE_STATEMENT_FAILED,
            err,
        };
    },

};

export default actions;