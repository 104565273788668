const actions = {
    GET_CATEGORY_BEGIN: 'GET_CATEGORY_BEGIN',
    GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
    GET_CATEGORY_FAILED: 'GET_CATEGORY_FAILED',
  
    ADD_CATEGORY_BEGIN: 'ADD_CATEGORY_BEGIN',
    ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
    ADD_CATEGORY_FAILED: 'ADD_CATEGORY_FAILED',
  
    UPDATE_CATEGORY_BEGIN: 'UPDATE_CATEGORY_BEGIN',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILED: 'UPDATE_CATEGORY_FAILED',
  
    DELETE_CATEGORY_BEGIN: 'DELETE_CATEGORY_BEGIN',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_FAILED: 'DELETE_CATEGORY_FAILED',
  
    getCategoryBegin: () => {
      return {
        type: actions.GET_CATEGORY_BEGIN,
      };
    },
  
    getCategorySuccess: data => {
      return {
        type: actions.GET_CATEGORY_SUCCESS,
        data,
      };
    },
  
    getCategoryFailed: err => {
      return {
        type: actions.GET_CATEGORY_FAILED,
        err,
      };
    },
  
    addCategoryBegin: () => {
      return {
        type: actions.ADD_CATEGORY_BEGIN,
      };
    },
  
    addCategorySuccess: data => {
      return {
        type: actions.ADD_CATEGORY_SUCCESS,
        data,
      };
    },
  
    addCategoryFailed: err => {
      return {
        type: actions.ADD_CATEGORY_FAILED,
        err,
      };
    },
  
    updateCategoryBegin: () => {
      return {
        type: actions.UPDATE_CATEGORY_BEGIN,
      };
    },
  
    updateCategorySuccess: data => {
      return {
        type: actions.UPDATE_CATEGORY_SUCCESS,
        data,
      };
    },
  
    updateCategoryFailed: err => {
      return {
        type: actions.UPDATE_CATEGORY_FAILED,
        err,
      };
    },
  
    deleteCategoryBegin: () => {
      return {
        type: actions.DELETE_CATEGORY_BEGIN,
      };
    },
  
    deleteCategorySuccess: data => {
      return {
        type: actions.DELETE_CATEGORY_SUCCESS,
        data,
      };
    },
  
    deleteCategoryFailed: err => {
      return {
        type: actions.DELETE_CATEGORY_FAILED,
        err,
      };
    },
  };
  
  export default actions;