/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

export { ellipsis };

export const getBase64 = (img, callback) => {
  console.log(img, "image")
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};


export const getImageUrl = (value) => {
  return `https://${process.env.REACT_APP_BUCKET_NAME}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/${value}`
}

export const dataURLtoFile = (dataurl, filename) => {

  var arr = dataurl?.split(','),
    mime = arr?.[0].match(/:(.*?);/)[1],
    bstr = atob(arr?.[1]),
    n = bstr?.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}


export const validateImages = (file,name, isRequired = true) => {
  const isJpgOrPng = file?.type === 'image/jpeg' || file?.type === 'image/png' || file?.type === 'image/jpg';
  const isLt2M = file?.size / 1024 / 1024 < 2;

  if(!file && isRequired) {
    return {
      validateStatus: 'error',
      errorMsg: `${name} is required`,
    };
  }
  else if (!isJpgOrPng) {
    return {
      validateStatus: 'error',
      errorMsg: 'You can only upload JPG/PNG/JPEG file!',
    };
  }
  else if (!isLt2M) {
    return {
      validateStatus: 'error',
      errorMsg: 'Image must smaller than 2MB!',
    };
  }
  else {
    return {
      validateStatus: 'success',
      errorMsg: null,

    }
  }
}

export const validateFiles = (file,name, isRequired = true) => {
  const isLt2M = file?.size / 1024 / 1024 < 2;

  if(!file && isRequired) {
    return {
      validateStatus: 'error',
      errorMsg: `${name} is required`,
    };
  }
  else if (!isLt2M) {
    return {
      validateStatus: 'error',
      errorMsg: 'Files must smaller than 2MB!',
    };
  }
  else {
    return {
      validateStatus: 'success',
      errorMsg: null,

    }
  }
}