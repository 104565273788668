import axios from 'axios';
import { SERVER_URL } from '../utils/constants';
import { getLocalAccessToken, getLocalRefreshToken, removeSession, updateLocalAccessToken } from './token';
import { message } from 'antd';

const instance = axios.create({
  baseURL: SERVER_URL
});

instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common['Accept'] = 'application/json';
const CancelToken = axios.CancelToken;

instance.interceptors.request.use((config) => {
  const token = getLocalAccessToken();
  if (token || token !== undefined) {
    config.headers["Authorization"] = `Bearer ${token}`
  }
  return config;
},
  (error) => {
    message.error("Something went wrong")
    return Promise.reject(error);
  })


instance.interceptors.response.use((res) => {
  return res;
}, async (err) => {
  const originalConfig = err?.config;
  if (originalConfig.url !== "/login" && err.response) {
    if ((err.response.status === 401 || err.response.status === 422 || err.response.status === 403) && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        const response = await axios.post(`${process.env.REACT_APP_HOST}/token/refresh`, {}, {
          headers: {
            Authorization: `Bearer ${getLocalRefreshToken()}`,
          }
        })
        const { access_token } = response.data;
        updateLocalAccessToken(access_token);
        originalConfig.headers[
          "Authorization"
        ] = `bearer ${access_token}`;
        return instance(originalConfig);
      } catch (err) {
        window.location = '/login';
        removeSession();
        message.error("Please login again");
        return Promise.reject(err)
      }
    }
    // else if (err.response.status = 422) {
    //   window.location = '/login';
    //   message.error("Something went wrong");
    //   removeSession();
    // }
    else return
  }
})

export default instance;
export { CancelToken };