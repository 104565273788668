const actions = {
    GET_CONTACT_BEGIN: 'GET_CONTACT_BEGIN',
    GET_CONTACT_SUCCESS: 'GET_CONTACT_SUCCESS',
    GET_CONTACT_FAILED: 'GET_CONTACT_FAILED',

    ADD_CONTACT_BEGIN: 'ADD_CONTACT_BEGIN',
    ADD_CONTACT_SUCCESS: 'ADD_CONTACT_SUCCESS',
    ADD_CONTACT_FAILED: 'ADD_CONTACT_FAILED',

    UPDATE_CONTACT_BEGIN: 'UPDATE_CONTACT_BEGIN',
    UPDATE_CONTACT_SUCCESS: 'UPDATE_CONTACT_SUCCESS',
    UPDATE_CONTACT_FAILED: 'UPDATE_CONTACT_FAILED',

    DELETE_CONTACT_BEGIN: 'DELETE_CONTACT_BEGIN',
    DELETE_CONTACT_SUCCESS: 'DELETE_CONTACT_SUCCESS',
    DELETE_CONTACT_FAILED: 'DELETE_CONTACT_FAILED',

    GET_CONTACT_BEGIN: 'GET_CONTACT_BEGIN',
    GET_CONTACT_SUCCESS: 'GET_CONTACT_SUCCESS',
    GET_CONTACT_FAILED: 'GET_CONTACT_FAILED',

    getContactBegin: () => {
        return {
            type: actions.GET_CONTACT_BEGIN
        }
    },

    getContactSuccess: (data) => {
        return {
            type: actions.GET_CONTACT_SUCCESS,
            data
        }
    },

    getContactFailed: (err) => {
        return {
            type: actions.GET_CONTACT_FAILED,
            err
        }
    },

    addContactBegin: () => {
        return {
            type: actions.ADD_CONTACT_BEGIN
        }
    },

    addContactSuccess: (data) => {
        return {
            type: actions.ADD_CONTACT_SUCCESS,
            data
        }
    },

    addContactFailed: (err) => {
        return {
            type: actions.ADD_CONTACT_FAILED,
            err
        }
    },

    updateContactBegin: () => {
        return {
            type: actions.UPDATE_CONTACT_BEGIN
        }
    },

    updateContactSuccess: (data) => {
        return {
            type: actions.UPDATE_CONTACT_SUCCESS,
            data
        }
    },

    updateContactFailed: (err) => {
        return {
            type: actions.UPDATE_CONTACT_FAILED,
            err
        }
    },

    deleteContactBegin: () => {
        return {
            type: actions.DELETE_CONTACT_BEGIN
        }
    },

    deleteContactSuccess: (data) => {
        return {
            type: actions.DELETE_CONTACT_SUCCESS,
            data
        }
    },

    deleteContactFailed: (err) => {
        return {
            type: actions.DELETE_CONTACT_FAILED,
            err
        }
    },

    getSingleContactBegin: () => {
        return {
            type: actions.GET_CONTACT_BEGIN
        }
    },

    getSingleContactSuccess: (data) => {
        return {
            type: actions.GET_CONTACT_SUCCESS,
            data
        }
    },

    getSingleContactFailed: (err) => {
        return {
            type: actions.GET_CONTACT_FAILED,
            err
        }
    },
}

export default actions;