import { Col, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Route } from 'react-router-dom';
import { CardBarChart2 } from './style';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { PageHeader } from '../../components/page-headers/page-headers';
import { getAllDashboardData } from '../../redux/dashboard/actionCreator';
import { Main } from '../styled';
import { Redirect } from 'react-router-dom';
import SignIn from '../profile/authentication/overview/SignIn';

const Dashboard = () => {
  const dispatch = useDispatch();

  const { dashboard, loading, role } = useSelector(state => {
    return {
      role: state.auth.user.role,
      dashboard: state.dashboard.datas,
      loading: state.dashboard.loading,
    };
  });

  useEffect(() => {
    if (localStorage.getItem('user')) {
      getAllDashboardData(dispatch);
      window.scrollTo(0, 0);
    }
  }, []);

  //  console.log(localStorage.getItem("user"))

  if (!localStorage.getItem('user')) {
    return <Redirect to={`/login`} />;
  }

  return loading ? (
    <div className="spin">
      <Spin />
    </div>
  ) : (
    <>
      <PageHeader ghost title={role === 'superuser' ? 'Superuser Dashboard' : 'Dashboard'} />
      <Main>
        <Row gutter={25}>
          {/* USER */}
          <Col xxl={6} md={8} sm={8} xs={24}>
            <Cards headless>
              <div className="card-chunk">
                <CardBarChart2>
                  <Heading as="h1">{dashboard.total_vendor_count ?? 0}</Heading>
                  <span>Total Vendor Count</span>
                </CardBarChart2>
              </div>
            </Cards>
          </Col>

          {/* CAR */}
          <Col xxl={6} md={8} sm={8} xs={24}>
            <Cards headless>
              <div className="card-chunk">
                <CardBarChart2>
                  <Heading as="h1">{dashboard.active_count}</Heading>
                  <span>Active Product Count</span>
                </CardBarChart2>
              </div>
            </Cards>
          </Col>

          <Col xxl={6} md={8} sm={8} xs={24}>
            <Cards headless>
              <div className="card-chunk">
                <CardBarChart2>
                  <Heading as="h1">{dashboard.pending_count}</Heading>
                  <span>Pending Product Count</span>
                </CardBarChart2>
              </div>
            </Cards>
          </Col>
        </Row>
        <Row gutter={25}>
          {/* USER */}
          <Col xxl={6} md={8} sm={8} xs={24}>
            <Cards headless>
              <div className="card-chunk">
                <CardBarChart2>
                  <Heading as="h1">{dashboard.vendor_request_count}</Heading>
                  <span>Vendor Request Count</span>
                </CardBarChart2>
              </div>
            </Cards>
          </Col>

          {/* CAR */}
          <Col xxl={6} md={8} sm={8} xs={24}>
            <Cards headless>
              <div className="card-chunk">
                <CardBarChart2>
                  <Heading as="h1">{dashboard.category_data?.length}</Heading>
                  <span>Total Categories</span>
                </CardBarChart2>
              </div>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Dashboard;
