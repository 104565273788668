import actions from './actions';

const {
  GET_CATEGORY_BEGIN, GET_CATEGORY_SUCCESS, GET_CATEGORY_FAILED,
  ADD_CATEGORY_BEGIN, ADD_CATEGORY_SUCCESS, ADD_CATEGORY_FAILED,
  UPDATE_CATEGORY_BEGIN, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_FAILED,
  DELETE_CATEGORY_BEGIN, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_FAILED
} = actions;

const initState = {
  categories: [],
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  loading: false,
  error: null,
};

const CategoryReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: data.categories,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: false
      };
    case GET_CATEGORY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_CATEGORY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: data,
        loading: false,
      };
    case UPDATE_CATEGORY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_CATEGORY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default CategoryReducer;