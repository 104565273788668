// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// USER COMPONENTS
import Pages from './pages';
import withAdminLayout from '../../layout/withAdminLayout';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
const Dashboard = lazy(() => import('../../container/dashboard'));
const User = lazy(() => import('../../container/user/User'));
const Vendor = lazy(() => import('../../container/request/Request'))
const Category = lazy(() => import('../../container/category/Category'));
const Profile = lazy(() => import('../../container/profile/myProfile/Index'));
const Blog = lazy(() => import('../../container/blog/Blog'))
const CreateBlog = lazy(() => import('../../container/blog/overview/CreateBlog'))
const Myprofile = lazy(() => import('../../container/profile/myProfile/Index'));
const DisplayBlog = lazy(() => import('../../container/blog/overview/DisplayBlog'));
const EditBlog = lazy(() => import('../../container/blog/overview/EditBlog'));
const Product = lazy(() => import('../../container/product/Product'))
const CreatePage = lazy(() => import('../../container/page/overview/CreatePage'))
const DisplayPage = lazy(() => import('../../container/page/overview/DisplayPage'))
const Page = lazy(() => import('../../container/page/Page'))
const EditPage = lazy(() => import('../../container/page/overview/EditPage'))
const DisplayProduct = lazy(() => import('../../container/product/overview/DisplayProduct'))
const Coupon = lazy(() => import('../../container/coupon/Coupon'))
const DisplayCoupon = lazy(() => import('../../container/coupon/overview/DisplayCoupon'))
const ContactQueries = lazy(() => import('../../container/contact-queries/contact-queries'))
const Settings = lazy(() => import('../../container/settings/Setttings'))
const OrderManagement = lazy(() => import("../../container/order/order"))
const VendorPayments = lazy(() => import("../../container/payableStatement/payableStatement"))
const OrderDetails = lazy(() => import("../../container/order/overview/OrderDetails"))
const UserReferral = lazy(() => import("../../container/referral/referral"))
const Banners = lazy(() => import("../../container/banner/banner"))

const Admin = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path={path} component={Dashboard} />
        {/* <Route exact path={"/"} component={<Redirect to="/superuser"/>} /> */}
        <Route exact path={`${path}/users`} component={User} />
        <Route exact path={`${path}/category`} component={Category} />
        <Route exact path={`${path}/request`} component={Vendor} />
        <Route exact path={`${path}/profile`} component={Profile} />
        <Route exact path={`${path}/blog`} component={Blog} />
        <Route exact path={`${path}/blog/add`} component={CreateBlog} />
        <Route exact path={`${path}/blogs/view/:id`} component={DisplayBlog} />
        <Route exact path={`${path}/blogs/edit/:id`} component={EditBlog} />
        <Route exact path={`${path}/product`} component={Product} />
        <Route exact path={`${path}/page`} component={Page} />
        <Route exact path={`${path}/page/add`} component={CreatePage} />
        <Route exact path={`${path}/pages/view/:id`} component={DisplayPage} />
        <Route exact path={`${path}/pages/edit/:id`} component={EditPage} />
        <Route exact path={`${path}/product/details/:id`} component={DisplayProduct} />
        <Route exact path={`${path}/coupon`} component={Coupon} />
        <Route exact path={`${path}/coupon/details/:id`} component={DisplayCoupon} />
        <Route exact path={`${path}/contact-queries`} component={ContactQueries} />
        <Route exact path={`${path}/settings`} component={Settings} />
        <Route exact path={`${path}/order`} component={OrderManagement} />
        <Route exact path={`${path}/banners`} component={Banners} />



        <Route path={`${path}`} component={Pages} />
        <Route path={`${path}/profile/myProfile`} component={Myprofile} />
        <Route path={`${path}/payments`} component={VendorPayments} />
        <Route path={`${path}/order/:id`} component={OrderDetails} />
        <Route path={`${path}/referral`} component={UserReferral} />

      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin, 'admin');