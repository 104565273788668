import actions from './actions';

const {
    GET_SETTING_BEGIN, GET_SETTING_SUCCESS, GET_SETTING_FAILED,
    GET_DELIVERY_ADDRESS_BEGIN, GET_DELIVERY_ADDRESS_SUCCESS, GET_DELIVERY_ADDRESS_FAILED,
    UPDATE_SETTING_BEGIN, UPDATE_SETTING_SUCCESS, UPDATE_SETTING_FAILED,
} = actions;

const initState = {
    settings: [],
    deliveryAddress: [],
    total: 0,
    currentPage: 1,
    pageLimit: 50,
    loading: false,
    error: null,
};

const UserReducer = (state = initState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_SETTING_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_SETTING_SUCCESS:
            return {
                ...state,
                settings: data.settings,
                total: data.total || 0,
                currentPage: data.currentPage || 1,
                pageLimit: data.pageLimit || state.pageLimit,
                loading: false,
            };
        case GET_SETTING_FAILED:
            return {
                ...state,
                error: err,
                loading: false,
            };
        case GET_DELIVERY_ADDRESS_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_DELIVERY_ADDRESS_SUCCESS:
            return {
                ...state,
                deliveryAddress: data.deliveryAddress,
                loading: false,
            };
        case GET_DELIVERY_ADDRESS_FAILED:
            return {
                ...state,
                error: err,
                loading: false,
            };
        case UPDATE_SETTING_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_SETTING_SUCCESS:
            return {
                ...state,
                settings: data,
                loading: false,
            };
        case UPDATE_SETTING_FAILED:
            return {
                ...state,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};
export default UserReducer;