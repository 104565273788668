import instance from '../axios';
import actions from './actions';

const { getDashboardDataBegin, getDashboardDataSuccess, getDashboardDataFailed } = actions;

// GETTING ALL DASHBOARD DATAS
export const getAllDashboardData = async (dispatch) => {
  try {
    dispatch(getDashboardDataBegin());
    const res = await instance.get('/data/admin');
    dispatch(getDashboardDataSuccess(res.data));
  } catch (err) {
    message.error('Something went wrong');
    dispatch(getDashboardDataFailed(err?.response?.data?.msg));
    }
}