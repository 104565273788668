import actions from './actions';

const {
  GET_PAGE_BEGIN, GET_PAGE_SUCCESS, GET_PAGE_FAILED,
  ADD_PAGE_BEGIN, ADD_PAGE_SUCCESS, ADD_PAGE_FAILED,
  UPDATE_PAGE_BEGIN, UPDATE_PAGE_SUCCESS, UPDATE_PAGE_FAILED,
  DELETE_PAGE_BEGIN, DELETE_PAGE_SUCCESS, DELETE_PAGE_FAILED
} = actions;

const initState = {
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  pages: [],
  loading: false,
  error: null,
};


const PageReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_PAGE_SUCCESS:
      return {
        ...state,
        total: data.totalPages,
        pages: data.pages,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: false,
      };
    case GET_PAGE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_PAGE_SUCCESS:
      return {
        ...state,
        total: state.total + 1,
        pages: data,
        loading: false,
      };
    case ADD_PAGE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        pages: data,
        loading: false,
      };
    case UPDATE_PAGE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_PAGE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PAGE_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        pages: data,
        loading: false,
      };
    case DELETE_PAGE_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default PageReducer;