import React from 'react';
import PropTypes from 'prop-types';
import { PageHeaderStyle } from './style';

const PageHeader = props => {
  const { title, subTitle, routes, buttons, ghost, bgColor, className, subText } = props;
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px 30px",
          paddingTop:"25px"
        }}
      >
        <div >
          <h2 style={{
            marginBottom: "4px",
            fontSize: "20px",
            fontWeight: "600",
            textTransform: "uppercase"
          }}>{title}</h2>
          <p
            style={{ fontSize: "14px", color: "#787878", fontWeight: "400" }}
          >{subText}</p>
        </div>
        <div>{buttons}</div>
        {/* <PageHeaderStyle
          style={{
            backgroundColor: 'rgb(244, 245, 247)',
          }}
          // onBack={() => window.history.back()}
          className={className}
          title={title}
          // subTitle={subTitle}
          // breadcrumb={routes && { routes }}
          extra={buttons}
          ghost={ghost}
        />
        <div>List of all Agrobase users. Basically there are there types of users according to their level of authority.</div> */}
      </div >
    </>
  );
};

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bgColor: PropTypes.string,
  className: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  buttons: PropTypes.array,
  ghost: PropTypes.bool,
  subText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export { PageHeader };
