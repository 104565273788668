import actions from './actions';

const {
    GET_USER_REFERRAL_BEGIN, GET_USER_REFERRAL_SUCCESS, GET_USER_REFERRAL_FAILED,
    CHANGE_USER_REFERRAL_BEGIN, CHANGE_USER_REFERRAL_SUCCESS, CHANGE_USER_REFERRAL_FAILED,
} = actions;

const initState = {
    users: [],
    total: 0,
    currentPage: 1,
    pageLimit: 15,
    loading: false,
    error: null,
};

const UserReferralReducer = (state = initState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_USER_REFERRAL_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_USER_REFERRAL_SUCCESS:
            return {
                ...state,
                users: data.users,
                total: data.total || 0,
                currentPage: data.currentPage || 1,
                pageLimit: data.pageLimit || state.pageLimit,
                loading: false,
            };
        case GET_USER_REFERRAL_FAILED:
            return {
                ...state,
                error: err,
                loading: false,
            };

        case CHANGE_USER_REFERRAL_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case CHANGE_USER_REFERRAL_SUCCESS:
            return {
                ...state,
                users: data,
                loading: false,
            };
        case CHANGE_USER_REFERRAL_FAILED:
            return {
                ...state,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};
export default UserReferralReducer;