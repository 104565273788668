import Cookies from "js-cookie"


export const setLocalRefreshToken = (refresh, ...args) => {
    return Cookies.set("refreshToken", refresh, ...args)
}

export const setLocalAccessToken = (access, ...args) => {
    return Cookies.set("accessToken", access, ...args)
}
export const setRememberMe = (remember, ...args) => {
    return Cookies.set("remember", remember, ...args)
}

export const getLocalRefreshToken = () => {
    const refreshToken = Cookies.get("refreshToken");
    return refreshToken;
}

export const getLocalAccessToken = () => {

    const accessToken = Cookies.get("accessToken");
    return accessToken
}

export const updateLocalAccessToken = (access) => {
    let remember = Cookies.get("remember");
    let config = remember ? { expires: 13, secure: true } : { secure: true };
    setLocalAccessToken(access, config);
}

export const setUser = (user) => {
    localStorage.setItem("user", user)
}

export const getUser = () => {
    return localStorage.getItem("user")
}

export const removeSession = () => {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("user_role");
    Cookies.remove("remember");
    localStorage.removeItem("user")
}