import { combineReducers } from 'redux';


import UserReducer from './users/reducers';
import Dashboard from './dashboard/reducers';
import RequestReducer from './request/reducers';
import headerSearchReducer from './headerSearch/reducers';
import CategoryReducer from './category/reducers';
import BlogReducer from './blog/reducers';
import ProductReducer from './product/reducer';
import PageReducer from './page/reducer';
import CouponReducer from './coupon/reducer';

import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import PlaceReducer from './places/reducer';
import ContactReducer from './contact-queries/reducer';
import SettingReducer from "./settings/reducers"
import OrderReducer from "./order/reducer"
import PaymentReducer from './payableStatement/reducer';
import UserReferralReducer from './referral/reducer';
import StoreReducer from './store/reducer';
import BannerReducer from './banner/reducers';

const rootReducers = combineReducers({
  auth: authReducer,
  user: UserReducer,
  headerSearch: headerSearchReducer,
  dashboard: Dashboard,
  request: RequestReducer,
  category: CategoryReducer,
  blog: BlogReducer,
  page: PageReducer,
  product: ProductReducer,
  coupon: CouponReducer,

  notification: readNotificationReducer,
  ChangeLayoutMode,
  place: PlaceReducer,
  contacts: ContactReducer,
  settings: SettingReducer,
  orders:OrderReducer,
  payments: PaymentReducer,
  referral:UserReferralReducer,
  store:StoreReducer,
  banners:BannerReducer
});

export default rootReducers;