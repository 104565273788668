import { message } from 'antd';
import Cookies from 'js-cookie';
import axios from '../axios';
import { removeSession, setLocalAccessToken, setLocalRefreshToken, setRememberMe, setUser } from '../token';
import actions from './actions';

const {
  loginBegin, loginSuccess, loginErr,
  updateProfileBegin, updateProfileSuccess, updateProfileErr,
  updatePasswordBegin, updatePasswordSuccess, updatePasswordErr,
  forgotPasswordBegin, forgotPasswordSuccess, forgotPasswordErr,
  logoutBegin, logoutSuccess, logoutErr
} = actions;


// FOR LOGIN
const login = (credential, history) => async dispatch => {

  try {
    dispatch(loginBegin());
    const req = {
      mobileNumber: credential.mobileNumber,
      password: credential.password
    }
    const res = await axios.post('/login', req);
    const { token, refresh_token, user } = res?.data;
    if (user.role === 'superuser') {
      let config = credential.remember ? { expires: 13, secure: true } : { secure: true };
      setLocalRefreshToken(refresh_token, config);
      setLocalAccessToken(token, config);
      setRememberMe(credential.remember, config)
      Cookies.set('user_role', user.role, config);
      setUser(JSON.stringify(user));
      dispatch(loginSuccess(user));
      history.push(`/${user.role}`);
    }
    else dispatch(loginErr('You must be an superuser'))
  } catch (err) {
    // dispatch(loginErr(typeof (err.response.data.msg) === 'object' ? err.response.data.msg.en : err.response.data.message));
    setTimeout(() => {
      dispatch(loginErr(null));
    }, 2000)
  }
};


// FORGOT PASSWORD
export const forgotPassword = (datas) => async (dispatch) => {
  try {
    dispatch(forgotPasswordBegin());
    const res = await axios.post(`/auth/forgot-password`, datas);
    if(res){
      window.location="/login";
      message.success("Please login in again")
    }
    dispatch(forgotPasswordSuccess('sometext'));
    message.success('Please check your email!')
  } catch (err) {
    message.error('Something went wrong');
    dispatch(forgotPasswordErr(err?.response?.data?.msg));
  }
}

// UPDATING PROFILE
export const updateProfile = (datas) => async (dispatch) => {
  try {
    dispatch(updateProfileBegin());
    const res = await axios.patch(`/update`, datas);
    // dispatch(updateProfileSuccess(res.data.user));
    message.success('Profile updated successfully');
    localStorage.setItem("user",JSON.stringify(res.data.user));
    location.reload();
  } catch (err) {
    message.error('Something went wrong');
    dispatch(updateProfileErr(err?.response?.data?.msg));
  }
}

// UPDATING PASSWORD
export const updatePassword = (datas, form) => async (dispatch) => {
  try {
    dispatch(updatePasswordBegin());
    const res = await axios.patch(`/change-password`, datas);
    // dispatch(updatePasswordSuccess(res.data.user));
    window.location="/login";
    message.success('Password chanaged successfully')
    form.resetFields();
    removeSession();
  } catch (err) {
    message.error("Something went wrong")
    dispatch(updatePasswordErr(err?.response?.data?.message));
  }
}

// FOR LOGOUT
const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      removeSession();
      Cookies.remove('user_role');
      Cookies.remove('currentPath');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut };
