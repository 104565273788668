const actions = {
    GET_COUPON_BEGIN: 'GET_COUPON_BEGIN',
    GET_COUPON_SUCCESS: 'GET_COUPON_SUCCESS',
    GET_COUPON_FAILED: 'GET_COUPON_FAILED',
  
    ADD_COUPON_BEGIN: 'ADD_COUPON_BEGIN',
    ADD_COUPON_SUCCESS: 'ADD_COUPON_SUCCESS',
    ADD_COUPON_FAILED: 'ADD_COUPON_FAILED',
  
    UPDATE_COUPON_BEGIN: 'UPDATE_COUPON_BEGIN',
    UPDATE_COUPON_SUCCESS: 'UPDATE_COUPON_SUCCESS',
    UPDATE_COUPON_FAILED: 'UPDATE_COUPON_FAILED',
  
    DELETE_COUPON_BEGIN: 'DELETE_COUPON_BEGIN',
    DELETE_COUPON_SUCCESS: 'DELETE_COUPON_SUCCESS',
    DELETE_COUPON_FAILED: 'DELETE_COUPON_FAILED',
  
    getCouponBegin: () => {
      return {
        type: actions.GET_COUPON_BEGIN,
      };
    },
  
    getCouponSuccess: data => {
      return {
        type: actions.GET_COUPON_SUCCESS,
        data,
      };
    },
  
    getCouponFailed: err => {
      return {
        type: actions.GET_COUPON_FAILED,
        err,
      };
    },
  
    addCouponBegin: () => {
      return {
        type: actions.ADD_COUPON_BEGIN,
      };
    },
  
    addCouponSuccess: data => {
      return {
        type: actions.ADD_COUPON_SUCCESS,
        data,
      };
    },
  
    addCouponFailed: err => {
      return {
        type: actions.ADD_COUPON_FAILED,
        err,
      };
    },
  
    updateCouponBegin: () => {
      return {
        type: actions.UPDATE_COUPON_BEGIN,
      };
    },
  
    updateCouponSuccess: data => {
      return {
        type: actions.UPDATE_COUPON_SUCCESS,
        data,
      };
    },
  
    updateCouponFailed: err => {
      return {
        type: actions.UPDATE_COUPON_FAILED,
        err,
      };
    },
  
    deleteCouponBegin: () => {
      return {
        type: actions.DELETE_COUPON_BEGIN,
      };
    },
  
    deleteCouponSuccess: data => {
      return {
        type: actions.DELETE_COUPON_SUCCESS,
        data,
      };
    },
  
    deleteCouponFailed: err => {
      return {
        type: actions.DELETE_COUPON_FAILED,
        err,
      };
    },
  };
  
  export default actions;