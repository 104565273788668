import { Button, Checkbox, Form, Input, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Heading from '../../../../components/heading/heading';
import { login } from '../../../../redux/authentication/actionCreator';
import { AuthWrapper } from './style';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector(state => {
    return {
      isLoading: state.auth.loading,
      error: state.auth.error,
    }
  })
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);

  // After the user submit the login credentials
  const handleSubmit = (values) => dispatch(login({ ...values, remember: checked }, history));

  // ALERTING
  useEffect(() => {
    if (error) message.error(error)
  }, [error]);




  // useEffect(() => {
  //   // CHECK IF THE USER IS AUTHENTICATED OR NOT
  //   dispatch(checkUserAuthenticated(history));
  // }, [])

  const onChange = (e) => {
    setChecked(e.target.checked);
  };


  if (localStorage.getItem("user")) {
    return <Redirect to={`/superuser`} />
  }

  return isLoading ? (
    <div className="spin">
      <Spin />
    </div>
  ) : (
    <AuthWrapper>
      <div className="auth-contents">
        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">
            Sign in to Admin
          </Heading>
          <Form.Item
            name="mobileNumber"
            rules={[{ message: 'Please enter your number!', required: true }]}
            label="Mobile Number"
          >
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password"
            rules={[{ message: 'Please enter your password !', required: true }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          {/* <div className="auth-form-action">
            <Checkbox onChange={onChange}>Keep me logged in</Checkbox>
            <NavLink className="forgot-pass-link" to="/forgotPassword">
              Forgot password?
            </NavLink>
          </div> */}
          <Form.Item>
            <Button className="btn-signin" htmlType="submit" style={{ backgroundColor: '#ffeab6', color: '#6b4e08' }} size="large">
              {isLoading ? 'Loading...' : 'Sign In'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default SignIn;