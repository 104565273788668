const actions = {
    GET_PAGE_BEGIN: 'GET_PAGE_BEGIN',
    GET_PAGE_SUCCESS: 'GET_PAGE_SUCCESS',
    GET_PAGE_FAILED: 'GET_PAGE_FAILED',
  
    ADD_PAGE_BEGIN: 'ADD_PAGE_BEGIN',
    ADD_PAGE_SUCCESS: 'ADD_PAGE_SUCCESS',
    ADD_PAGE_FAILED: 'ADD_PAGE_FAILED',
  
    UPDATE_PAGE_BEGIN: 'UPDATE_PAGE_BEGIN',
    UPDATE_PAGE_SUCCESS: 'UPDATE_PAGE_SUCCESS',
    UPDATE_PAGE_FAILED: 'UPDATE_PAGE_FAILED',
  
    DELETE_PAGE_BEGIN: 'DELETE_PAGE_BEGIN',
    DELETE_PAGE_SUCCESS: 'DELETE_PAGE_SUCCESS',
    DELETE_PAGE_FAILED: 'DELETE_PAGE_FAILED',
  
    getPageBegin: () => {
      return {
        type: actions.GET_PAGE_BEGIN,
      };
    },
  
    getPageSuccess: data => {
      return {
        type: actions.GET_PAGE_SUCCESS,
        data,
      };
    },
  
    getPageFailed: err => {
      return {
        type: actions.GET_PAGE_FAILED,
        err,
      };
    },
  
    addPageBegin: () => {
      return {
        type: actions.ADD_PAGE_BEGIN,
      };
    },
  
    addPageSuccess: data => {
      return {
        type: actions.ADD_PAGE_SUCCESS,
        data,
      };
    },
  
    addPageFailed: err => {
      return {
        type: actions.ADD_PAGE_FAILED,
        err,
      };
    },
  
    updatePageBegin: () => {
      return {
        type: actions.UPDATE_PAGE_BEGIN,
      };
    },
  
    updatePageSuccess: data => {
      return {
        type: actions.UPDATE_PAGE_SUCCESS,
        data,
      };
    },
  
    updatePageFailed: err => {
      return {
        type: actions.UPDATE_PAGE_FAILED,
        err,
      };
    },
  
    deletePageBegin: () => {
      return {
        type: actions.DELETE_PAGE_BEGIN,
      };
    },
  
    deletePageSuccess: data => {
      return {
        type: actions.DELETE_PAGE_SUCCESS,
        data,
      };
    },
  
    deletePageFailed: err => {
      return {
        type: actions.DELETE_PAGE_FAILED,
        err,
      };
    },
  };
  
  export default actions;