const actions = {
    GET_PRODUCT_BEGIN: 'GET_PRODUCT_BEGIN',
    GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
    GET_PRODUCT_FAILED: 'GET_PRODUCT_FAILED',
  
    ADD_PRODUCT_BEGIN: 'ADD_PRODUCT_BEGIN',
    ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
    ADD_PRODUCT_FAILED: 'ADD_PRODUCT_FAILED',
  
    UPDATE_PRODUCT_BEGIN: 'UPDATE_PRODUCT_BEGIN',
    UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT_FAILED: 'UPDATE_PRODUCT_FAILED',

    CHANGE_PRODUCT_STATUS_BEGIN: 'CHANGE_PRODUCT_STATUS_BEGIN',
    CHANGE_PRODUCT_STATUS_SUCCESS: 'CHANGE_PRODUCT_STATUS_SUCCESS',
    CHANGE_PRODUCT_STATUS_FAILED: 'CHANGE_PRODUCT_STATUS_FAILED',
  
    DELETE_PRODUCT_BEGIN: 'DELETE_PRODUCT_BEGIN',
    DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
    DELETE_PRODUCT_FAILED: 'DELETE_PRODUCT_FAILED',
  
    getProductBegin: () => {
      return {
        type: actions.GET_PRODUCT_BEGIN,
      };
    },
  
    getProductSuccess: data => {
      return {
        type: actions.GET_PRODUCT_SUCCESS,
        data,
      };
    },
  
    getProductFailed: err => {
      return {
        type: actions.GET_PRODUCT_FAILED,
        err,
      };
    },
  
    addProductBegin: () => {
      return {
        type: actions.ADD_PRODUCT_BEGIN,
      };
    },
  
    addProductSuccess: data => {
      return {
        type: actions.ADD_PRODUCT_SUCCESS,
        data,
      };
    },
  
    addProductFailed: err => {
      return {
        type: actions.ADD_PRODUCT_FAILED,
        err,
      };
    },
  
    updateProductBegin: () => {
      return {
        type: actions.UPDATE_PRODUCT_BEGIN,
      };
    },
  
    updateProductSuccess: data => {
      return {
        type: actions.UPDATE_PRODUCT_SUCCESS,
        data,
      };
    },
  
    updateProductFailed: err => {
      return {
        type: actions.UPDATE_PRODUCT_FAILED,
        err,
      };
    },

    changeProductStatusBegin: () => {
      return {
        type: actions.CHANGE_PRODUCT_STATUS_BEGIN,
      };
    },
  
    changeProductStatusSuccess: () => {
      return {
        type: actions.CHANGE_PRODUCT_STATUS_SUCCESS,
        
      };
    },
  
    changeProductStatusFailed: err => {
      return {
        type: actions.CHANGE_PRODUCT_STATUS_FAILED,
        err,
      };
    },
  
    deleteProductBegin: () => {
      return {
        type: actions.DELETE_PRODUCT_BEGIN,
      };
    },
  
    deleteProductSuccess: data => {
      return {
        type: actions.DELETE_PRODUCT_SUCCESS,
        data,
      };
    },
  
    deleteProductFailed: err => {
      return {
        type: actions.DELETE_PRODUCT_FAILED,
        err,
      };
    },
  };
  
  export default actions;