import actions from './actions';

const {
  GET_PRODUCT_BEGIN, GET_PRODUCT_SUCCESS, GET_PRODUCT_FAILED,
  ADD_PRODUCT_BEGIN, ADD_PRODUCT_SUCCESS, ADD_PRODUCT_FAILED,
  UPDATE_PRODUCT_BEGIN, UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAILED,
  CHANGE_PRODUCT_STATUS_BEGIN, CHANGE_PRODUCT_STATUS_SUCCESS, CHANGE_PRODUCT_STATUS_FAILED,
  DELETE_PRODUCT_BEGIN, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAILED
} = actions;

const initState = {
  products: [],
  total: 0,
  currentPage: 1,
  pageLimit: 15,
  loading: false,
  error: null,
};

const ProductReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_PRODUCT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        products: data.products,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: false,
      };
    case GET_PRODUCT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_PRODUCT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        products: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_PRODUCT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_PRODUCT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: data,
        loading: false,
      };
    case UPDATE_PRODUCT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
      case CHANGE_PRODUCT_STATUS_BEGIN:
        return {
          ...state,
          loading: true,
        };
      case CHANGE_PRODUCT_STATUS_SUCCESS:
        return {
          ...state,
          products: data,
          loading: false,
        };
      case CHANGE_PRODUCT_STATUS_FAILED:
        return {
          ...state,
          error: err,
          loading: false,
        };
    case DELETE_PRODUCT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_PRODUCT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default ProductReducer;