const actions = {
    GET_SETTING_BEGIN: 'GET_SETTING_BEGIN',
    GET_SETTING_SUCCESS: 'GET_SETTING_SUCCESS',
    GET_SETTING_FAILED: 'GET_SETTING_FAILED',

    GET_DELIVERY_ADDRESS_BEGIN: 'GET_DELIVERY_ADDRESS_BEGIN',
    GET_DELIVERY_ADDRESS_SUCCESS: 'GET_DELIVERY_ADDRESS_SUCCESS',
    GET_DELIVERY_ADDRESS_FAILED: 'GET_DELIVERY_ADDRESS_FAILED',

    UPDATE_SETTING_BEGIN: 'UPDATE_SETTING_BEGIN',
    UPDATE_SETTING_SUCCESS: 'UPDATE_SETTING_SUCCESS',
    UPDATE_SETTING_FAILED: 'UPDATE_SETTING_FAILED',

    getSettingBegin: () => {
        return {
            type: actions.GET_SETTING_BEGIN,
        };
    },

    getSettingSuccess: data => {
        return {
            type: actions.GET_SETTING_SUCCESS,
            data,
        };
    },

    getSettingFailed: err => {
        return {
            type: actions.GET_SETTING_FAILED,
            err,
        };
    },

    getDeliveryAddressBegin: () => {
        return {
            type: actions.GET_DELIVERY_ADDRESS_BEGIN,
        };
    },

    getDeliveryAddressSuccess: data => {
        return {
            type: actions.GET_DELIVERY_ADDRESS_SUCCESS,
            data,
        };
    },

    getDeliveryAddressFailed: err => {
        return {
            type: actions.GET_DELIVERY_ADDRESS_FAILED,
            err,
        };
    },

    updateSettingBegin: () => {
        return {
            type: actions.UPDATE_SETTING_BEGIN,
        };
    },

    updateSettingSuccess: data => {
        return {
            type: actions.UPDATE_SETTING_SUCCESS,
            data,
        };
    },

    updateSettingFailed: err => {
        return {
            type: actions.UPDATE_SETTING_FAILED,
            err,
        };
    },
};

export default actions;