const actions = {
    GET_STORE_BEGIN: 'GET_STORE_BEGIN',
    GET_STORE_SUCCESS: 'GET_STORE_SUCCESS',
    GET_STORE_FAILED: 'GET_STORE_FAILED',

    getStoreBegin: () => {
        return {
            type: actions.GET_STORE_BEGIN,
        };
    },

    getStoreSuccess: data => {
        return {
            type: actions.GET_STORE_SUCCESS,
            data,
        };
    },

    getStoreFailed: err => {
        return {
            type: actions.GET_STORE_FAILED,
            err,
        };
    },



};

export default actions;