import React from 'react';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Admin from '../../routes/admin';
import Cookies from 'js-cookie';

const ProtectedRoute = () => {
  const user_role = Cookies.get('user_role');
  const isLoggedIn = localStorage.getItem("user")

  return isLoggedIn ? (
    user_role === 'superuser' ? (
      <Route component={Admin} path={'/superuser'} />
    ) : (
      <Redirect push to="/login" />
    )
  ) : (
    <Redirect push to="/login" />
  );
};

// ProtectedRoute.propTypes = {
//   component: propTypes.object.isRequired,
//   path: propTypes.string.isRequired,
// };

export default ProtectedRoute;
