import actions from './actions';

const {
  GET_USER_BEGIN, GET_USER_SUCCESS, GET_USER_FAILED,
  ADD_USER_BEGIN, ADD_USER_SUCCESS, ADD_USER_FAILED,
  UPDATE_USER_BEGIN, UPDATE_USER_SUCCESS, UPDATE_USER_FAILED,
  DELETE_USER_BEGIN, DELETE_USER_SUCCESS, DELETE_USER_FAILED,
  GET_STOREDETAILS_BEGIN, GET_STOREDETAILS_SUCCESS, GET_STOREDETAILS_FAILED

} = actions;

const initState = {
  users: [],
  storeDetails: {},
  storeLoading: false,
  storeError: null,
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  loading: false,
  error: null,
};

const UserReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        users: data.users,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: false,
      };
    case GET_USER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: data,
        loading: false,
      };
    case UPDATE_USER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_STOREDETAILS_BEGIN:
      return {
        ...state,
        storeLoading: true,
      };
    case GET_STOREDETAILS_SUCCESS:
      return {
        ...state,
        storeDetails: data,
        storeLoading: false,
      };
    case GET_STOREDETAILS_FAILED:
      return {
        ...state,
        storeError: err,
        storeLoading: false,
      };
    default:
      return state;
  }
};
export default UserReducer;