const actions = {
    GET_BANNER_BEGIN: 'GET_BANNER_BEGIN',
    GET_BANNER_SUCCESS: 'GET_BANNER_SUCCESS',
    GET_BANNER_FAILED: 'GET_BANNER_FAILED',

    ADD_BANNER_BEGIN: 'ADD_BANNER_BEGIN',
    ADD_BANNER_SUCCESS: 'ADD_BANNER_SUCCESS',
    ADD_BANNER_FAILED: 'ADD_BANNER_FAILED',

    UPDATE_BANNER_BEGIN: 'UPDATE_BANNER_BEGIN',
    UPDATE_BANNER_SUCCESS: 'UPDATE_BANNER_SUCCESS',
    UPDATE_BANNER_FAILED: 'UPDATE_BANNER_FAILED',

    DELETE_BANNER_BEGIN: 'DELETE_BANNER_BEGIN',
    DELETE_BANNER_SUCCESS: 'DELETE_BANNER_SUCCESS',
    DELETE_BANNER_FAILED: 'DELETE_BANNER_FAILED',

    getBannerBegin: () => {
        return {
            type: actions.GET_BANNER_BEGIN,
        };
    },

    getBannerSuccess: data => {
        return {
            type: actions.GET_BANNER_SUCCESS,
            data,
        };
    },

    getBannerFailed: err => {
        return {
            type: actions.GET_BANNER_FAILED,
            err,
        };
    },

    addBannerBegin: () => {
        return {
            type: actions.ADD_BANNER_BEGIN,
        };
    },

    addBannerSuccess: data => {
        return {
            type: actions.ADD_BANNER_SUCCESS,
            data,
        };
    },

    addBannerFailed: err => {
        return {
            type: actions.ADD_BANNER_FAILED,
            err,
        };
    },

    updateBannerBegin: () => {
        return {
            type: actions.UPDATE_BANNER_BEGIN,
        };
    },

    updateBannerSuccess: data => {
        return {
            type: actions.UPDATE_BANNER_SUCCESS,
            data,
        };
    },

    updateBannerFailed: err => {
        return {
            type: actions.UPDATE_BANNER_FAILED,
            err,
        };
    },

    deleteBannerBegin: () => {
        return {
            type: actions.DELETE_BANNER_BEGIN,
        };
    },

    deleteBannerSuccess: data => {
        return {
            type: actions.DELETE_BANNER_SUCCESS,
            data,
        };
    },

    deleteBannerFailed: err => {
        return {
            type: actions.DELETE_BANNER_FAILED,
            err,
        };
    },
};

export default actions;