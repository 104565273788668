import actions from './actions';

const {
    GET_PAYABLE_STATEMENT_BEGIN, GET_PAYABLE_STATEMENT_SUCCESS, GET_PAYABLE_STATEMENT_FAILED,
    GET_SINGLE_PAYABLE_STATEMENT_BEGIN, GET_SINGLE_PAYABLE_STATEMENT_SUCCESS, GET_SINGLE_PAYABLE_STATEMENT_FAILED,
    UPDATE_PAYABLE_STATEMENT_BEGIN, UPDATE_PAYABLE_STATEMENT_SUCCESS, UPDATE_PAYABLE_STATEMENT_FAILED
} = actions;

const initState = {
    total: 0,
    currentPage: 1,
    pageLimit: 50,
    payments: [],
    payment: {},
    loading: false,
    error: null,
};


const PaymentReducer = (state = initState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_PAYABLE_STATEMENT_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_PAYABLE_STATEMENT_SUCCESS:
            return {
                ...state,
                total: data.total,
                payments: data.payments,
                currentPage: data.currentPage || 1,
                pageLimit: data.pageLimit || state.pageLimit,
                loading: false,
            };
        case GET_PAYABLE_STATEMENT_FAILED:
            return {
                ...state,
                error: err,
                loading: false,
            };

        case GET_SINGLE_PAYABLE_STATEMENT_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case GET_SINGLE_PAYABLE_STATEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                payment: data,
            };
        case GET_SINGLE_PAYABLE_STATEMENT_FAILED:
            return {
                ...state,
                loading: false,
                error: err,
            };
        case UPDATE_PAYABLE_STATEMENT_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_PAYABLE_STATEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_PAYABLE_STATEMENT_FAILED:
            return {
                ...state,
                loading: false,
                error: err,
            };

        default:
            return state;
    }
};
export default PaymentReducer;