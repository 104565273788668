import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {/* FOR DASHBOARD */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}`}>
              <FeatherIcon icon="home" />
            </NavLink>
          )
        }
        key="dashboard"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Dashboard
        </NavLink>
      </Menu.Item>

      <SubMenu key="user-data" icon={!topMenu && <FeatherIcon icon="users" />} title="Users">
        {/* FOR USER  */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/users`}>
                <FeatherIcon icon="users" />
              </NavLink>
            )
          }
          key="user"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
            User
          </NavLink>
        </Menu.Item>
        {/* FOR VENDOR REQUEST  */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/request`}>
                <FeatherIcon icon="user" />
              </NavLink>
            )
          }
          key="request"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/request`}>
            Vendor Request
          </NavLink>
        </Menu.Item>

        {/* FOR VENDOR REQUEST  */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/referral`}>
                <FeatherIcon icon="refresh-cw" />
              </NavLink>
            )
          }
          key="referral"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/referral`}>
            User Referral Reset
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu key="product-data" icon={!topMenu && <FeatherIcon icon="package" />} title="Products">
        {/* FOR CATEGORY SECTION */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/category`}>
                <FeatherIcon icon="package" />
              </NavLink>
            )
          }
          key="category"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/category`}>
            Categories
          </NavLink>
        </Menu.Item>

        {/* FOR PRODUCT REQUEST  */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/product`}>
                <FeatherIcon icon="folder" />
              </NavLink>
            )
          }
          key="product"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/product`}>
            Product
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/order`}>
              <FeatherIcon icon="shopping-cart" />
            </NavLink>
          )
        }
        key="order"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/order`}>
          Orders
        </NavLink>
      </Menu.Item>

      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/payments`}>
              <FeatherIcon icon="book-open" />
            </NavLink>
          )
        }
        key="payments"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/payments`}>
          Payable Statement
        </NavLink>
      </Menu.Item>

      {/* FOR COUPON SECTION */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/coupon`}>
              <FeatherIcon icon="map" />
            </NavLink>
          )
        }
        key="coupon"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/coupon`}>
          Coupons
        </NavLink>
      </Menu.Item>

      <SubMenu key="extras" icon={!topMenu && <FeatherIcon icon="book" />} title="Pages">
        {/* FOR USER  */}
        {/* <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/page`}>
                <FeatherIcon icon="book" />
              </NavLink>
            )
          }
          key="page"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/page`}>
            Page
          </NavLink>
        </Menu.Item> */}
        {/* FOR VENDOR REQUEST  */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/blog`}>
                <FeatherIcon icon="book-open" />
              </NavLink>
            )
          }
          key="blog"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/blog`}>
            Blogs
          </NavLink>
        </Menu.Item>
        {/* FOR VENDOR REQUEST  */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/banners`}>
                <FeatherIcon icon="maximize" />
              </NavLink>
            )
          }
          key="banners"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/banners`}>
            Banners
          </NavLink>
        </Menu.Item>
      </SubMenu>

      {/* FOR BLOG SECTION */}
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/blog`}>
              <FeatherIcon icon="book-open" />
            </NavLink>
          )
        }
        key="blog"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/blog`}>
          Blogs
        </NavLink>
      </Menu.Item> */}

      {/* FOR PAGE SECTION */}
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/page`}>
              <FeatherIcon icon="book" />
            </NavLink>
          )
        }
        key="page"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/page`}>
          Pages
        </NavLink>
      </Menu.Item> */}

      {/* FOR CONTACT QUERIES SECTION */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/contact-queries`}>
              <FeatherIcon icon="message-square" />
            </NavLink>
          )
        }
        key="contact-queries"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/contact-queries`}>
          Contact Queries
        </NavLink>
      </Menu.Item>

      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/settings`}>
              <FeatherIcon icon="settings" />
            </NavLink>
          )
        }
        key="settings"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/settings`}>
          Settings
        </NavLink>
      </Menu.Item>

      {/* FOR VEHICLES + BRAND + MODEL +BODY TYPE +COLOR */}
      {/* <SubMenu key="vehicle-data" icon={!topMenu && <FeatherIcon icon="truck" />} title="Vehicle"> */}
      {/* FOR BRAND */}
      {/* <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/brands`}>
                <FeatherIcon icon="pocket" />
              </NavLink>
            )
          }
          key="brand"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/brands`}>
            Brand
          </NavLink>
        </Menu.Item> */}

      {/* FOR MODEL */}
      {/* <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/models`}>
                <FeatherIcon icon="zap" />
              </NavLink>
            )
          }
          key="model"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/models`}>
            Model
          </NavLink>
        </Menu.Item>
        </SubMenu> */}

      {/* FOR BODY TYPE */}
      {/* <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/body-types`}>
                <FeatherIcon icon="framer" />
              </NavLink>
            )
          }
          key="bodytype"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/body-types`}>
            Body Type
          </NavLink>
        </Menu.Item> */}

      {/* FOR COLOR */}
      {/* <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/colors`}>
                <FeatherIcon icon="bold" />
              </NavLink>
            )
          }
          key="vehiclecolor"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/colors`}>
            Color
          </NavLink>
        </Menu.Item> */}

      {/* FOR VEHICLE  */}
      {/* <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/vehicles`}>
                <FeatherIcon icon="truck" />
              </NavLink>
            )
          }
          key="vehicle"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/vehicles`}>
            Vehicle
          </NavLink>
        </Menu.Item> */}

      {/* FOR VEHICLE COMPARING */}
      {/* <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/compare-vehicles`}>
                <FeatherIcon icon="thumbs-up" />
              </NavLink>
            )
          }
          key="vehicle comparing"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/compare-vehicles`}>
            Compare Vehicle
          </NavLink>
        </Menu.Item>
      </SubMenu> */}

      {/* FOR BLOGS +BLOG CATEGORY */}
      {/* <SubMenu key="layout" icon={!topMenu && <FeatherIcon icon="layout" />} title="Blogs">
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/blog-categories`}>
                <FeatherIcon icon="copy" />
              </NavLink>
            )
          }
          key="category"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/blog-categories`}>
            Category
          </NavLink>
        </Menu.Item> */}
      {/* 
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/blogs`}>
                <FeatherIcon icon="layout" />
              </NavLink>
            )
          }
          key="blog"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/blogs`}>
            Blogs
          </NavLink>
        </Menu.Item>

        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/blog-videos`}>
                <FeatherIcon icon="youtube" />
              </NavLink>
            )
          }
          key="blog-video"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/blog-videos`}>
            Blog Video
          </NavLink>
        </Menu.Item>
      </SubMenu> */}

      {/* FOR ADS  */}
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/ads`}>
              <FeatherIcon icon="book" />
            </NavLink>
          )
        }
        key="ads"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/ads`}>
          Advertisement
        </NavLink>
      </Menu.Item> */}

      {/* FOR SUBSCRIBER  */}
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/subscribers`}>
              <FeatherIcon icon="user-check" />
            </NavLink>
          )
        }
        key="subscriber"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/subscribers`}>
          Subscribers
        </NavLink>
      </Menu.Item> */}

      {/* FOR CONTACT INFORMATION  */}
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/contact-information`}>
              <FeatherIcon icon="map-pin" />
            </NavLink>
          )
        }
        key="contact-information"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/contact-information`}>
          Contact Information
        </NavLink>
      </Menu.Item> */}

      {/* FAQS */}
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/faqs`}>
              <FeatherIcon icon="help-circle" />
            </NavLink>
          )
        }
        key="faq"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/faqs`}>
          FAQ
        </NavLink>
      </Menu.Item> */}

      {/* REVIEWS */}
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/reviews`}>
              <FeatherIcon icon="message-circle" />
            </NavLink>
          )
        }
        key="review"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/reviews`}>
          Reviews
        </NavLink>
      </Menu.Item> */}

      {/* Ratings */}
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/ratings`}>
              <FeatherIcon icon="star" />
            </NavLink>
          )
        }
        key="rating"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/ratings`}>
          Ratings
        </NavLink>
      </Menu.Item> */}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
