const actions = {
    GET_REQUEST_BEGIN: 'GET_REQUEST_BEGIN',
    GET_REQUEST_SUCCESS: 'GET_REQUEST_SUCCESS',
    GET_REQUEST_FAILED: 'GET_REQUEST_FAILED',
  
    UPDATE_REQUEST_BEGIN: 'UPDATE_REQUEST_BEGIN',
    UPDATE_REQUEST_SUCCESS: 'UPDATE_REQUEST_SUCCESS',
    UPDATE_REQUEST_FAILED: 'UPDATE_REQUEST_FAILED',
  
    DELETE_REQUEST_BEGIN: 'DELETE_REQUEST_BEGIN',
    DELETE_REQUEST_SUCCESS: 'DELETE_REQUEST_SUCCESS',
    DELETE_REQUEST_FAILED: 'DELETE_REQUEST_FAILED',
  
    getRequestBegin: () => {
      return {
        type: actions.GET_REQUEST_BEGIN,
      };
    },
  
    getRequestSuccess: data => {
      return {
        type: actions.GET_REQUEST_SUCCESS,
        data,
      };
    },
  
    getRequestFailed: err => {
      return {
        type: actions.GET_REQUEST_FAILED,
        err,
      };
    },
  
    updateRequestBegin: () => {
      return {
        type: actions.UPDATE_REQUEST_BEGIN,
      };
    },
  
    updateRequestSuccess: data => {
      return {
        type: actions.UPDATE_REQUEST_SUCCESS,
        data,
      };
    },
  
    updateRequestFailed: err => {
      return {
        type: actions.UPDATE_REQUEST_FAILED,
        err,
      };
    },
  
    deleteRequestBegin: () => {
      return {
        type: actions.DELETE_REQUEST_BEGIN,
      };
    },
  
    deleteRequestSuccess: data => {
      return {
        type: actions.DELETE_REQUEST_SUCCESS,
        data,
      };
    },
  
    deleteRequestFailed: err => {
      return {
        type: actions.DELETE_REQUEST_FAILED,
        err,
      };
    },
  };
  
  export default actions;